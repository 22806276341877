/* CSS */
import "@js/parts/search";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}


// Project config
import SearchAutocomplete from '@js/search/modules/SearchAutocomplete';
import SearchResults from '@js/search/modules/SearchResults.js';
import TimeResults from '@js/search/modules/TimeResults';
import TopicResults from '@js/search/modules/TopicResults';

// DOM load
document.addEventListener('DOMContentLoaded', e => {
    // Autocomplete search
    SearchAutocomplete.init();

    // Mobile search button
    const mobileSearchBtn = document.querySelector('.search-btn-mobile');
    const searchInput = document.querySelector('.aa-Input');
    const detachedSearchBtn = document.querySelector('.aa-DetachedSearchButton');

    // Non-detached search
    if (mobileSearchBtn && searchInput) {
        // Add click event to mobile button to open autocomplete search
        mobileSearchBtn.addEventListener('click', () => {
            // Open search via focus on input
            searchInput.focus();
        });
    }

    // Detached search
    if (mobileSearchBtn && detachedSearchBtn) {
        // Add click event to mobile button to open autocomplete search
        mobileSearchBtn.addEventListener('click', () => {
            // Open search via clicking button
            detachedSearchBtn.click();
        });
    }
});

