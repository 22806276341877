// Imports
import algoliasearch from "algoliasearch";
import { h, render } from 'preact';

// Project imports
import Config from "@js/config/Config";
import utils from '@js/utils/utils';
import {NoResultsMessage} from "@js/templates/Helpers";
import PaginationShowMore from '@js/templates/PaginationShowMore';
import {StorySummary, StorySummaryColumnsWrapper} from '@js/templates/StorySummary';

class TimeResults {
    constructor() {
        this.attr = 'data-time';
        this.searchEl = document.querySelector(`[${this.attr}]`);

        // Check if the element is found
        if (this.searchEl) {
            // Setup search client
            this.searchClient = null;

            // Primary results container
            this.primaryResultsContainer = this.searchEl.querySelector('#results');

            // Preact state
            this.state = {
                enablePersonalization: false,
                loadingClass: 'is-loading',
                nbPages: 0,
                results: [],
                time: this.searchEl.getAttribute(this.attr),
                page: 0,
            };

            // Run search setup
            this.setup();
        }
    }

    setup() {
        // Variables
        const appId = Config.algoliaAppId;
        const apiKey = Config.algoliaSearchOnlyApi;
        const indexNameStories = Config.algoliaStoriesIndexName;

        // Setup Algolia search client
        this.searchClient = algoliasearch(appId, apiKey);

        // Search index
        this.indexStories = this.searchClient.initIndex(indexNameStories);

        this.timeRange = this.getTimeRange(this.state.time);

        // Perform search
        this.performSearch(this.state.page);
    }

    getTimeRange(time) {
        let timeRange = '';
        let minReadTime, maxReadTime;

        time = Number(time);

        if (time >= 0 && time <= 9) {
            // Limit this min value to be no lower than 0
            minReadTime = Math.max(time - 2, 0);
            maxReadTime = time + 2;
        } else if (time >= 10 && time <= 19) {
            minReadTime = time - 3;
            maxReadTime = time + 3;
        } else if (time >= 20 && time <= 40) {
            minReadTime = time - 5;
            maxReadTime = time + 5;
        } else {
            minReadTime = time - 10;
            maxReadTime = time + 10;
        }

        timeRange = `readTimeSearch:${minReadTime} TO ${maxReadTime}`;

        console.log({timeRange});

        return timeRange;
    }

    performSearch(page) {
        this.searchEl.classList.add(this.state.loadingClass);

        console.log('Perform Search');

        this.indexStories.search('', {
            enablePersonalization: this.state.enablePersonalization,
            numericFilters: [this.timeRange],
            hitsPerPage: 10,
            page: page
        })
            .then(({ hits, nbHits, nbPages }) => {
                console.log({hits});
                console.log({nbHits});
                console.log({nbPages});
                // Remove loading class
                this.searchEl.classList.remove(this.state.loadingClass);

                if (hits.length > 0) {
                    // Add total number of results to meta field
                    const storiesCountEl = this.searchEl.querySelector('.stories-count');
                    const totalEl = this.searchEl.querySelector('.stories-counter');
                    if (totalEl) {
                        // Format number to an overview once it is over 999 - e.g. 1k, 1.5k 40k, 345k
                        totalEl.innerHTML = utils.formatNumberOverview(nbHits);

                        // Show stories count element
                        storiesCountEl.classList.remove('hidden');
                    }

                    // Output style
                    let style = 'columns';

                    // Add new results
                    let newResults = hits.map(hit => <StorySummary hit={hit} style={style} />);
                    this.state.results = [...this.state.results, ...newResults];

                    let contentToRender = this.state.results;

                    if (style === 'columns') {
                        contentToRender = (
                            <StorySummaryColumnsWrapper>
                                {this.state.results}
                            </StorySummaryColumnsWrapper>
                        );
                    }

                    // Render results
                    render(contentToRender, this.primaryResultsContainer);

                    // Update state
                    this.state.page = page;
                    this.state.nbPages = nbPages;

                    // Pagination
                    this.renderPagination();
                } else {
                    // Render no results message
                    render(<NoResultsMessage title='No stories found.' message='<p>Try a different time.</p>' />, this.primaryResultsContainer);
                }
            });
    }

    renderPagination() {
        let paginationContainer = this.searchEl.querySelector('#pagination');

        console.log('Paginated entries: ' + this.state.page + '/' + (this.state.nbPages - 1));

        // If page is less that total pages, show pagination button
        if (this.state.page < (this.state.nbPages - 1)) {
            render(
                <PaginationShowMore
                    onClick={() => this.performSearch(this.state.page + 1)}
                />,
                paginationContainer
            );
        }
        else {
            paginationContainer.innerHTML = '';
        }
    }
}

// Export the Story class
export default new TimeResults;
