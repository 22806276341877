import { h } from 'preact';

export function TopicPill({ hit, storyTopicTrackingEvent = '', topicTrackingEvent = '' }) {
    const url = '/topic/' + hit.slug;

    return (
        <a className="topic-pill" href={url} aria-label={`Explore captivating short stories encompassing ${hit.topic}`} data-track-click={`st;${hit.topic};${storyTopicTrackingEvent}`} data-track-click2={`t;${hit.topic};${topicTrackingEvent}`}>
            {hit.topic}
        </a>
    );
}

export const TopicsWrapper = ({ children, classNames }) => (
    <div className={`topics-wrapper ${classNames}`}>
        {children}
    </div>
);
