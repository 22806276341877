// Imports
import algoliasearch from "algoliasearch";
import { h, render } from 'preact';
import PaginationShowMore from '@js/templates/PaginationShowMore';
import { StorySummary, StoryOverview } from '@js/templates/StorySummary';
import { UserSummary, UserOverview } from '@js/templates/UserSummary';
import { TopicPill, TopicsWrapper } from '@js/templates/TopicSummary';
//import { ListSummary, ListOverview } from '@js/templates/ListSummary';
import { SidebarWrapper, SidebarHeading, SidebarSeeAllButton } from '@js/templates/Sidebar';

// Project imports
import Config from "@js/config/Config";

class SearchResults {
    constructor() {
        this.attr = 'data-search';
        this.searchEl = document.querySelector(`[${this.attr}]`);

        // Check if the element is found
        if (this.searchEl) {
            // Setup search client
            this.searchClient = null;

            // Get search query
            let params = new URLSearchParams(window.location.search);

            // Preact state
            this.state = {
                enablePersonalization: false,
                loadingClass: 'is-loading',
                nbPages: 0,
                primaryResults: [],
                primarySearchType: this.searchEl.getAttribute(this.attr),
                page: 0,
                query: params.get('q') || '',
            };

            // Search setting for all types
            this.searchSettings = {
                stories: {
                    pagination: 10,
                    //secondarySearch: ['topics', 'lists', 'users'],
                    secondarySearch: ['topics', 'users'],
                    sidebarSettings: {
                        heading: 'Stories matching ' + this.state.query,
                        limit: 4,
                        seeAllUrl: '/search/stories?q=' + this.state.query
                    }
                },
                users: {
                    pagination: 10,
                    // secondarySearch: ['stories', 'topics', 'lists'],
                    secondarySearch: ['stories', 'topics'],
                    sidebarSettings: {
                        heading: 'People matching ' + this.state.query,
                        limit: 4,
                        seeAllUrl: '/search/users?q=' + this.state.query
                    }
                },
                topics: {
                    pagination: 30,
                    //secondarySearch: ['stories', 'users', 'lists'],
                    secondarySearch: ['stories', 'users'],
                    sidebarSettings: {
                        heading: 'Topics matching ' + this.state.query,
                        limit: 6,
                        seeAllUrl: '/search/topics?q=' + this.state.query
                    }
                }
                // lists: {
                //     pagination: 10,
                //     secondarySearch: ['stories', 'users', 'topics'],
                //     sidebarSettings: {
                //         heading: 'Lists matching ' + this.state.query,
                //         limit: 4,
                //         seeAllUrl: '/search/lists?q=' + this.state.query
                //     }
                // },
            };

            // Run search setup
            this.setup();
        }
    }

    setup() {
        // Variables
        const appId = Config.algoliaAppId;
        const apiKey = Config.algoliaSearchOnlyApi;

        // Setup Algolia search client
        this.searchClient = algoliasearch(appId, apiKey);

        // Initialise search indexed
        this.initIndexes();

        // Perform primary search
        this.performPrimarySearch(this.state.page);

        // Perform secondary search
        this.performSecondarySearches();
    }

    initIndexes() {
        // Search indexes
        this.indexStories = this.searchClient.initIndex(Config.algoliaStoriesIndexName);
        this.indexUsers = this.searchClient.initIndex(Config.algoliaUsersIndexName);
        this.indexTopics = this.searchClient.initIndex(Config.algoliaTopicsIndexName);
        //this.indexLists = this.searchClient.initIndex(Config.algoliaListsIndexName);
    }

    performPrimarySearch(page) {
        this.searchEl.classList.add(this.state.loadingClass);

        const index = this.getIndex(this.state.primarySearchType);

        index.search(this.state.query, {
            enablePersonalization: this.state.enablePersonalization,
            hitsPerPage: this.searchSettings[this.state.primarySearchType].pagination,
            page: page
        })
            .then(({ hits, nbPages }) => {
                // Remove loading class
                this.searchEl.classList.remove(this.state.loadingClass);

                let primaryResultsContainer = this.searchEl.querySelector('#results-primary');

                if (hits.length > 0) {
                    // Add new results
                    let newResults = hits.map(hit => this.summaryTemplateComponent(this.state.primarySearchType, hit));
                    this.state.primaryResults = [...this.state.primaryResults, ...newResults];

                    let contentToRender = this.state.primaryResults;

                    if (this.state.primarySearchType === 'topics') {
                        contentToRender = (
                            <TopicsWrapper>
                                {this.state.primaryResults}
                            </TopicsWrapper>
                        );
                    }

                    // Render results
                    render(contentToRender, primaryResultsContainer);

                    // Update state
                    this.state.page = page;
                    this.state.nbPages = nbPages;

                    // Pagination
                    this.renderPagination();
                } else {
                    primaryResultsContainer.innerHTML = `<h2 class="h3 font-sans mb-3">No results found.</h2>
                        <p>Make sure all words are spelled correctly.<br>
                        Try different keywords.<br>
                        Try more general keywords.</p>`;
                }
            });
    }

    performSecondarySearches() {
        // Create an array to store all promises
        const searchPromises = [];

        // Loop through secondary searches
        this.searchSettings[this.state.primarySearchType].secondarySearch.forEach(item => {
            const index = this.getIndex(item);

            // Create a promise for each secondary search
            const searchPromise = index.search(this.state.query, {
                enablePersonalization: this.state.enablePersonalization,
                hitsPerPage: this.searchSettings[item].sidebarSettings.limit
            })
                .then(({ hits, nbHits }) => {
                    return {
                        hits: hits,
                        nbHits: nbHits,
                        template: item,
                        heading: this.searchSettings[item].sidebarSettings.heading,
                        seeAllUrl: this.searchSettings[item].sidebarSettings.seeAllUrl,
                    };
                });

            // Push the promise into the array
            searchPromises.push(searchPromise);
        });

        // Use Promise.all to wait for all promises to resolve
        Promise.all(searchPromises)
            .then(results => {
                // Once all promises are resolved, render the results
                results.forEach(result => {
                    this.sidebarHits(result);
                });
            });
    }

    sidebarHits({hits, nbHits, template, heading, seeAllUrl}) {
        let sidebarContainer = this.searchEl.querySelector('#results-sidebar');

        if (hits.length > 0) {
            // Heading
            const headingComponent = heading
                ? <SidebarHeading text={heading} />
                : null;

            // Results
            let hitsComponents = hits.map(hit => this.overviewTemplateComponent(template, hit));

            // If template is topics, then wrap components in wrapper element
            if (template === 'topics') {
                hitsComponents = (
                    <TopicsWrapper>
                        {hitsComponents}
                    </TopicsWrapper>
                );
            }

            // See more button
            const seeAllButtonComponent = (seeAllUrl && nbHits > this.searchSettings[template].sidebarSettings.limit)
                ? <SidebarSeeAllButton url={seeAllUrl} />
                : null;

            const sidebarWrapperClass = 'sidebar-' + template + '-wrapper';

            // Wrap all content using WrapperComponent
            const contentToRender = (
                <SidebarWrapper classNames={sidebarWrapperClass}>
                    {headingComponent}
                    {hitsComponents}
                    {seeAllButtonComponent}
                </SidebarWrapper>
            );

            // Create a new div for each result
            const resultContainer = document.createElement('div');
            sidebarContainer.appendChild(resultContainer);

            render(contentToRender, resultContainer);
        }
    }

    renderPagination() {
        let paginationContainer = this.searchEl.querySelector('#pagination');

        console.log('Paginated entries: ' + this.state.page + '/' + (this.state.nbPages - 1));

        // If page is less that total pages, show pagination button
        if (this.state.page < (this.state.nbPages - 1)) {
            render(
                <PaginationShowMore
                    onClick={() => this.performPrimarySearch(this.state.page + 1)}
                />,
                paginationContainer
            );
        }
        else {
            paginationContainer.innerHTML = '';
        }
    }

    summaryTemplateComponent(type, hit) {
        switch (type) {
            case 'stories':
                return <StorySummary hit={hit} storyTrackingEvent="clickedAfterSearch" userTrackingEvent="convertedAfterSearch" />;
            case 'users':
                return <UserSummary hit={hit} trackingEvent="clickedAfterSearch" />;
            case 'lists':
                return <ListsSummary hit={hit} trackingEvent="clickedAfterSearch" />;
            case 'topics':
                return <TopicPill hit={hit} storyTopicTrackingEvent="clickedFilters" topicTrackingEvent="clicked" />;
            default:
                return null;
        }
    }

    overviewTemplateComponent(type, hit) {
        switch (type) {
            case 'stories':
                return <StoryOverview hit={hit} storyTrackingEvent="clickedAfterSearch" userTrackingEvent="convertedAfterSearch" />;
            case 'users':
                return <UserOverview hit={hit} trackingEvent="clickedAfterSearch" />;
            case 'lists':
                return <ListsOverview hit={hit} trackingEvent="clickedAfterSearch" />;
            case 'topics':
                return <TopicPill hit={hit} storyTopicTrackingEvent="clickedFilters" topicTrackingEvent="clicked" />;
            default:
                return null;
        }
    }

    getIndex(searchType) {
        switch (searchType) {
            case 'stories':
                return this.indexStories;
            case 'users':
                return this.indexUsers;
            case 'lists':
                return this.indexLists;
            case 'topics':
                return this.indexTopics;
            default:
                return null;
        }
    }
}

// Export the Story class
export default new SearchResults;
