import { h, Component } from 'preact';

export default class PaginationShowMore extends Component {
    render() {
        let { onClick } = this.props;

        return (
            <div className="pagination-gradient absolute bottom-0 left-0 flex items-end justify-center h-[110px] w-full">
                <button className="flex gap-1 items-center text-green-600 hover:text-green-700" onClick={onClick}>
                    Show More
                    <svg className="h-auto w-5" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
        );
    }
}
