// Imports
import { h } from 'preact';

function renderMessage(message) {
    return { __html: message };
}

export function NoResultsMessage({ title, message }) {
    return (
        <div className="text-center">
            <h2 className="type-h3 font-sans mb-3">{title}</h2>
            <div className="type" dangerouslySetInnerHTML={renderMessage(message)}/>
        </div>
    );
}
