// Imports
import algoliasearch from 'algoliasearch';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';

// Project imports
import Config from '@js/config/Config';

class SearchAutocomplete {
    constructor() {
        this.detachedMode = false;
        this.initResults = false;
    }

    init() {
        this.autocompleteId = 'autocomplete';
        this.autocompleteEl = document.getElementById(this.autocompleteId);

        if (this.autocompleteEl) {
            // Variables
            const appId = Config.algoliaAppId;
            const apiKey = Config.algoliaSearchOnlyApi;
            const querySuggestionIndexName = Config.algoliaSearchQuerySuggestionsIndexName;

            const searchClient = algoliasearch(appId, apiKey);

            // Define a flag to track whether the user has performed a search
            let hasPerformedSearch = false;

            // Recent searches
            const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
                key: 'main',
                limit: 4,
                transformSource({source}) {
                    return {
                        ...source,
                        // onSelect({ item, setIsOpen }) {
                        //     // Comment out if linking to search page
                        //     setIsOpen(true);
                        //
                        //     // Assuming the `setSearchState` function updates the search page state.
                        //     setSearchState({ query: item.query });
                        // },
                        getItemUrl({item}) {
                            return `/search?q=${item.label}`;
                        },
                        templates: {
                            ...source.templates,
                            item(params) {
                                const {item, html} = params;

                                return html`<a class="aa-ItemLink" href="/search/stories?q=${item.label}">
                                    ${source.templates.item(params).props.children}
                                </a>`;
                            },
                            header({items, html}) {
                                if (items.length === 0) {
                                    return null;
                                }

                                return html`<span class="aa-SourceHeaderTitle">Your searches</span>
                                <div class="aa-SourceHeaderLine"/>`;
                            },
                        },
                    };
                },
            });

            // Query suggestions
            const querySuggestionsPlugin = createQuerySuggestionsPlugin({
                searchClient,
                indexName: querySuggestionIndexName,
                getSearchParams({state}) {
                    return {
                        ...recentSearchesPlugin.data.getAlgoliaSearchParams(),
                        hitsPerPage: 4,
                    };
                },
                transformSource({source}) {
                    return {
                        ...source,
                        // onSelect({ item, setIsOpen }) {
                        //     // Comment out if linking to search page
                        //     setIsOpen(true);
                        //
                        //     // Assuming the `setSearchState` function updates the search page state.
                        //     setSearchState({ query: item.query });
                        // },
                        getItemUrl({item}) {
                            return `/search?q=${item.query}`;
                        },
                        templates: {
                            ...source.templates,
                            item(params) {
                                const {item, html} = params;

                                return html`<a class="aa-ItemLink" href="/search/stories?q=${item.query}">
                                    ${source.templates.item(params).props.children}
                                </a>`;
                            },
                            header({items, html}) {
                                if (items.length === 0) {
                                    return null;
                                }

                                return html`<span class="aa-SourceHeaderTitle">Popular searches</span>
                                <div class="aa-SourceHeaderLine"/>`;
                            },
                        },
                    };
                },
            });

            // Get query param
            let getQuery = new URLSearchParams(window.location.search);
            let searchQuery = null;
            if (getQuery) {
                searchQuery = getQuery.get('q');
            }

            // Autocomplete setup
            let autocompleteInstance = autocomplete({
                container: '#' + this.autocompleteId,
                detachedMediaQuery: this.detachedMode ? '' : '(max-width: 640px)', // Always turn on detached mode
                insights: true,
                openOnFocus: true,
                onSubmit({state}) {
                    // Go to default search page with query
                    window.location.href = `/search/stories?q=${encodeURIComponent(state.query)}`;
                },
                placeholder: searchQuery ? searchQuery : 'Search',
                plugins: [recentSearchesPlugin],
                //plugins: [recentSearchesPlugin, querySuggestionsPlugin],
                // onSelect({ item, setIsOpen }) {
                //     // Comment out if linking to search page
                //     setIsOpen(true);
                //
                //     // Assuming the `setSearchState` function updates the search page state.
                //     setSearchState({ query: item.query });
                // },
                getSources: ({ query }) => {
                    return this.detachedMode ? [
                        {
                            sourceId: 'stories',
                            getItemUrl({item}) {
                                return `${item.url}`;
                            },
                            getItems() {
                                return hasPerformedSearch ? getAlgoliaResults({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: Config.algoliaStoriesIndexName,
                                            query,
                                            params: {
                                                hitsPerPage: 6,
                                            },
                                        },
                                    ],
                                }) : [];
                            },
                            templates: {
                                header({items, html}) {
                                    if (items.length === 0) {
                                        return null;
                                    }

                                    return html`<span class="aa-SourceHeaderTitle">Stories</span>
                                    <div class="aa-SourceHeaderLine"/>`;
                                },
                                //item({ item, components }) {
                                item(params) {
                                    const {item, html} = params;

                                    return html`
                                        <a
                                            className="aa-ItemLink"
                                            href="${item.url}"
                                        >
                                            <div className="aa-ItemContent">
                                                <div className="aa-ItemContentBody">
                                                    <div className="aa-ItemContentTitle font-bold">
                                                        <!-- <components.Highlight hit={item} attribute="title" /> -->
                                                        ${item.title}
                                                    </div>
                                                    <div className="aa-ItemContentDescription">
                                                        <!-- <components.Snippet hit={item} attribute="summary" /> -->
                                                        <div>
                                                            By ${item.author.fullname}
                                                        </div>
                                                        <div>
                                                            <span>${item.readTime}</span>
                                                            <span class="dot-separator">·</span>
                                                            <span class="">Rating: ${item.rating}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aa-ItemActions">
                                                <button
                                                    className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                    type="button"
                                                    title="Select"
                                                >
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="20"
                                                        height="20"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </a>`;
                                },
                            },
                        },
                        {
                            sourceId: 'users',
                            getItemUrl({item}) {
                                return `${item.url}`;
                            },
                            getItems() {
                                return hasPerformedSearch ? getAlgoliaResults({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: Config.algoliaUsersIndexName,
                                            query,
                                            params: {
                                                hitsPerPage: 6,
                                            },
                                        },
                                    ],
                                }) : [];
                            },
                            templates: {
                                header({items, html}) {
                                    if (items.length === 0) {
                                        return null;
                                    }

                                    return html`<span class="aa-SourceHeaderTitle">Authors</span>
                                    <div class="aa-SourceHeaderLine"/>`;
                                },
                                //item({ item, components }) {
                                item(params) {
                                    const {item, html} = params;

                                    return html`
                                        <a
                                            className="aa-ItemLink"
                                            href="${item.url}"
                                        >
                                            <div className="aa-ItemContent flex-row">
                                                <img
                                                    class="rounded-full h-[40px] w-[40px]"
                                                    src=${item.profileImage[400]}
                                                    alt=${item.fullname}
                                                />

                                                <div className="aa-ItemContentBody">
                                                    <div className="aa-ItemContentTitle">
                                                        ${item.fullname}
                                                    </div>
                                                    <div className="aa-ItemContentDescription">
                                                        <span>@${item.username}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="aa-ItemActions">
                                                <button
                                                    className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
                                                    type="button"
                                                    title="Select"
                                                >
                                                    <svg
                                                        viewBox="0 0 24 24"
                                                        width="20"
                                                        height="20"
                                                        fill="currentColor"
                                                    >
                                                        <path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </a>`;
                                },
                            },
                        },
                    ] : [];
                },
                onStateChange({state}) {
                    // Update the flag based on the search state
                    hasPerformedSearch = state.query ? state.query.trim() !== '' : false;
                },
                render: ({elements, render, html}, root) => {
                    const {recentSearchesPlugin, querySuggestionsPlugin, stories, users} = elements;

                    let initResultsClass= this.initResults ? '_init-results' : '';
                    let contentToRender = '';

                    // Conditionally render search results or initial content based on whether a search has been performed
                    if (this.detachedMode) {
                        contentToRender = hasPerformedSearch
                            ? html`
                                <div class="aa-PanelSections _search _detached">
                                    <div class="aa-PanelSection--left">
                                        ${stories}
                                    </div>
                                    <div class="aa-PanelSection--right">
                                        ${users}
                                    </div>
                                </div>`
                            : html`
                                <div class="aa-PanelSections _init ${initResultsClass} _detached">
                                    <div class="aa-PanelSection--left">
                                        ${recentSearchesPlugin} ${querySuggestionsPlugin}
                                    </div>
                                    <div class="aa-PanelSection--right">
                                        ${stories} ${users}
                                        ${this.initResults ? html`${stories} ${users}` : ''}
                                    </div>
                                </div>`;
                    }
                    else {
                        contentToRender = html`
                            <div class="aa-PanelSections _init">
                                <div class="aa-PanelSection">
                                    ${recentSearchesPlugin} ${querySuggestionsPlugin}
                                </div>
                            </div>`;
                    }

                    render(html`
                        <div class="aa-PanelLayout aa-Panel--scrollable">${contentToRender}</div>`, root);
                },
            });
        }
    }
}

// Export the Story class
export default new SearchAutocomplete;
