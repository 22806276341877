// Imports
import algoliasearch from "algoliasearch";
import { h, render } from 'preact';

// Project imports
import Config from "@js/config/Config";
import utils from '@js/utils/utils';
import {NoResultsMessage} from "@js/templates/Helpers";
import PaginationShowMore from '@js/templates/PaginationShowMore';
import {StorySummary, StorySummaryColumnsWrapper} from '@js/templates/StorySummary';

class TopicResults {
    constructor() {
        this.attr = 'data-topic';
        this.searchEl = document.querySelector(`[${this.attr}]`);

        // Check if the element is found
        if (this.searchEl) {
            // Setup search client
            this.searchClient = null;

            // Primary results container
            this.primaryResultsContainer = this.searchEl.querySelector('#results');

            // Preact state
            this.state = {
                enablePersonalization: false,
                loadingClass: 'is-loading',
                nbPages: 0,
                results: [],
                topic: this.searchEl.getAttribute(this.attr),
                page: 0,
            };

            // Run search setup
            this.setup();
        }
    }

    setup() {
        // Variables
        const appId = Config.algoliaAppId;
        const apiKey = Config.algoliaSearchOnlyApi;
        const indexNameStories = Config.algoliaStoriesIndexName;
        const indexNameTopics = Config.algoliaTopicsIndexName;

        // Tag filters
        // Because Algolia can't show close queries or plurals with related filters,
        // we have to query the Topics and pass these into the Stories query to show better results
        // Filter should follow this format: https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
        this.tagFilters = [];


        // Setup Algolia search client
        this.searchClient = algoliasearch(appId, apiKey);

        // Search index
        this.indexStories = this.searchClient.initIndex(indexNameStories);
        this.indexTopics = this.searchClient.initIndex(indexNameTopics);

        // Get filters for search query
        // Do this first so we get the filters in one additional query and don't have to query on every pagination
        this.getFilters();
    }

    getFilters() {
        console.log('Get filters');

        this.indexTopics.search(this.state.topic, {
            hitsPerPage: 10,
        })
            .then(({ hits }) => {
                // Loop through hits and construct the string
                hits.forEach(hit => {
                    // Escape single quotes and double quotes in the topic attribute
                    //const escapedTopic = hit.topic.replace(/"/g, '\"');

                    this.tagFilters.push(`_tags:${hit.topic}`);
                });

                console.log('Filters:');
                console.log(this.tagFilters);

                // Check if there are any matching topics
                // If so, perform a search, else show error message
                if (hits.length > 0) {
                    // Perform initial primary search
                    this.performSearch(this.state.page);
                }
                else {
                    render(<NoResultsMessage title='No matching topics found.' message='<p>Make sure all words are spelled correctly.<br>Try a different topic.<br>Try more general keywords.</p>' />, this.primaryResultsContainer);
                }
            })
            .catch(error => {
                console.error('Error fetching search results: ', error);
            });
    }

    performSearch(page) {
        this.searchEl.classList.add(this.state.loadingClass);

        console.log('Perform Search');

        this.indexStories.search('', {
            enablePersonalization: this.state.enablePersonalization,
            facetFilters: [
                this.tagFilters
            ],
            hitsPerPage: 10,
            page: page
        })
            .then(({ hits, nbHits, nbPages }) => {
                // Remove loading class
                this.searchEl.classList.remove(this.state.loadingClass);

                if (hits.length > 0) {
                    // Add total number of results to meta field
                    const storiesCountEl = this.searchEl.querySelector('.stories-count');
                    const totalEl = this.searchEl.querySelector('.stories-counter');
                    if (totalEl) {
                        // Format number to an overview once it is over 999 - e.g. 1k, 1.5k 40k, 345k
                        totalEl.innerHTML = utils.formatNumberOverview(nbHits);

                        // Show stories count element
                        storiesCountEl.classList.remove('hidden');
                    }

                    // Output style
                    let style = 'columns';

                    // Add new results
                    let newResults = hits.map(hit => <StorySummary hit={hit} style={style} />);
                    this.state.results = [...this.state.results, ...newResults];

                    let contentToRender = this.state.results;

                    if (style === 'columns') {
                        contentToRender = (
                            <StorySummaryColumnsWrapper>
                                {this.state.results}
                            </StorySummaryColumnsWrapper>
                        );
                    }

                    // Render results
                    render(contentToRender, this.primaryResultsContainer);

                    // Update state
                    this.state.page = page;
                    this.state.nbPages = nbPages;

                    // Pagination
                    this.renderPagination();
                } else {
                    // Render no results message
                    render(<NoResultsMessage title='No stories found.' message='<p>Make sure all words are spelled correctly.<br>Try a different topic.<br>Try more general keywords.</p>' />, this.primaryResultsContainer);
                }
            });
    }

    renderPagination() {
        let paginationContainer = this.searchEl.querySelector('#pagination');

        console.log('Paginated entries: ' + this.state.page + '/' + (this.state.nbPages - 1));

        // If page is less that total pages, show pagination button
        if (this.state.page < (this.state.nbPages - 1)) {
            render(
                <PaginationShowMore
                    onClick={() => this.performSearch(this.state.page + 1)}
                />,
                paginationContainer
            );
        }
        else {
            paginationContainer.innerHTML = '';
        }
    }
}

// Export the Story class
export default new TopicResults;
